import React, { useState } from "react"
import photoService from "../../../services/photo"
import storyService from "../../../services/story"
import media from "../../../services/media"
import FeaturedStoriesDetailsEdit from "../featured-stories-details-edit/featured-stories-details-edit"
import InnerLayout from "../../layout/inner-layout/inner-layout"
const ViewFeaturedStories = ({ item, isCreate, children, ...props }) => {
  const [editState, setEditState] = useState(isCreate ? true : false)
  const [itemTags, setItemTags] = useState([])
  const [message, setMessage] = useState(null)
  const [messageType, setMessageType] = useState("success")

  const handleEditRequestError = (error, resolve) => {
    setErrorMessage("An error has occurred")
    console.log("view-photo: ERROR", error)
    resolve(false)
  }

  const setErrorMessage = message => {
    setMessage(message)
    setMessageType("error")
  }
  const setSuccessMessage = message => {
    setMessage(message)
    setMessageType("success")
  }

  const setInfoMessage = message => {
    setMessage(message)
    setMessageType("info")
  }
  // This should probably be split into two or more functions
  const toggleEditing = (
    modifiedItem,
    itemPublished,
    itemDeleted,
  ) => {
    return new Promise(resolve => {
      if (itemDeleted) {
        setInfoMessage("Deleting Featured Story...")

        console.log('Delete story:', modifiedItem)

        const updatedObject = {
          ...modifiedItem,
          isFeaturedStory: false
        }

        storyService.updateStory(updatedObject).then(
          deletedStory => {
            setSuccessMessage("Featured story deleted successfully")
            // return to search results? Display message saying story has been deleted?
            setEditState(false)
            resolve(true)
          },
          error => {
            handleEditRequestError(error, resolve)
          }
        )
      } else if (modifiedItem && !itemPublished) {
        setInfoMessage("Saving Featured Stories...")

        console.log('Modified', modifiedItem.media)
        
        _.map(modifiedItem.media, (modified) => {
          console.log('Save story:', modified)

          const updatedObject = {
            ...modified,
            isFeaturedStory: true
          }

          storyService.updateStory(updatedObject).then(
            updatedStory => {
              setSuccessMessage("Featured story updated successfully")
              // refresh the page? Probably enough to just extend item with the savedStory
              // _.extend(item, savedPhoto)
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        })  
      } 
      else {
        // user cancelled
        setSuccessMessage(null)
        setEditState(false)
        resolve(true)
      }
    })
  }

  return (
      <InnerLayout>
        <FeaturedStoriesDetailsEdit
          message={message}
          messageType={messageType}
          item={item}
          itemTags={itemTags}
          setEditStateFn={toggleEditing}
        ></FeaturedStoriesDetailsEdit>
      </InnerLayout>
  )
}

export default ViewFeaturedStories
