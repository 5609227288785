import classNames from "classnames"
import { convertFromRaw, convertToRaw } from "draft-js"
import _ from "lodash"
import { navigate } from "gatsby"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-form"
import { MdCheckCircle, MdHelpOutline, MdInsertPhoto, MdKeyboardArrowLeft } from "react-icons/md"
import ReactTooltip from "react-tooltip"
import FeaturedMediaDisplay from "../featured-photos-display/featured-photos-display"
import AttachMedia from "../../common/attach-media/attach-media"
import ModalWindow from "../../common/modal-window/modal-window"
import Button from "../../common/button/button"
import StoryEditor from "../../common/form/story-editor/story-editor"
import GenericMessage from "../../common/generic-message/generic-message"
import photoService from "../../../services/photo"
import storyService from "../../../services/story"
import mediaFetch from "../../../services/media"
import ImageEditor from '../../common/image-editor/image-editor'
import styles from "./featured-stories-details-edit.module.scss"

const FeaturedStoriesDetailsEdit = ({
  item,
  setEditStateFn,
  itemTags,
  message,
  messageType,
  ...props
}) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [publishing, setPublishing] = useState(false)
  const [_messageType, setMessageType] = useState(messageType)
  const [_message, setMessage] = useState(message)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [media, setMedia] = useState([])
  const [activeMedia, setActiveMedia] = useState()

  console.log('Message:', _message)

  useEffect(() => {
    setMessage(message)
    setMessageType(messageType)
  }, [message, messageType])

  useEffect(() => {
    let addedMedias = []

    let branch = ''
    if (process.env.GATSBY_USE_FHAA === 'true') {
      branch = 'FHAA'
    }
    else {
      branch = 'AFPA'
    }

    storyService.findFeaturedStories(branch).then(
      response => {
        if (response.stories.length > 3) {
          setSubmitAttempted(true)
        }
        const NewMedias = _.union(media, response.stories)
        setMedia(NewMedias)
      },
      error => {
        console.log('Error:', error)
      }
    )
  }, [])

  const defaultValues = React.useMemo(() => item, [])

  const { Form, meta, pushFieldValue } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: () => {
      console.log('onSubmit')
      var updatedObj = null
      if (meta.isTouched && meta.isValid) {
        updatedObj = {
          media
        }
      }

      if (media.length < 4) {
        setMessage("Please add four stories")
        setMessageType("error")       
      }
      else if (media.length > 4) {
        setMessage("Too many stories")
        setMessageType("error")       
      }
      else {
        setSubmitAttempted(true)
        setEditStateFn(updatedObj, publishing, false)
      }  

      setTimeout(() => {
        setMessage('')
        setMessageType('')
      }, 4000)

    },
    validate: () => {
      setSubmitAttempted(false)

      return false
    },
  })

  const onBackClick = async () => {
    await navigate("/")
  }

  const onSaveClick = () => {
    setPublishing(false)
  }

  const onCancelClick = () => {
    navigate("/")
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Insert Story" closeHandler={onModalClose}>
          <AttachMedia onMediaAttached={onMediaAttached} mediaType={'Story'}/>
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const onAttachMediaClick = e => {
    setModalOpen(true)
    setSubmitAttempted(false)
  }

  const onSave = () => {
    setModalEditOpen(false)
  }

  const onMediaAttached = attachedMedia => {
    var addedMedias = []

    addedMedias.push(attachedMedia)

    const NewMedias = _.union(media, addedMedias)

    setMedia(NewMedias)

    setModalOpen(false)
  }

  const getStoryStatus = () => {
    var status = "Published"
    if (item.status === "DRAFT" || !item.status) {
      status = "Unpublished"
    }
    return (
      <div
        className={classNames(
          styles.status,
          item.status === "DRAFT" || !item.status ? styles.draft : ""
        )}
      >
        <MdCheckCircle /> {status}
      </div>
    )
  }
  const getEditedDate = () => {
    var date = item.updatedAt ? item.updatedAt : item.createdAt
    if (date) {
      return (
        <div className={styles.lastUpdate}>
          Last edited:
          {moment(date)
            .format("MMM DD, YYYY")
            .toString()}
        </div>
      )
    } else {
      return ""
    }
  }

  const getActions = () => {
    return (
      <div className={styles.actions}>
        <Button
          id="add-featured-stories"
          type="button"
          label="Add Story"
          btnStyle="primary"
          btnSize="large"
          fullWidth
          onClick={onAttachMediaClick}
          disableStatus={media.length > 3}
        />
        <div className={styles.subButton}>
          <Button
            id="save-featured-stories"
            label="Save"
            type="submit"
            btnStyle="secondary"
            btnSize="large"
            fullWidth
            prependIcon="MdSave"
            onClick={onSaveClick}
            disableStatus={submitAttempted}
          />
          {/* <Button
            id="cancel-featured-stories"
            label="Cancel"
            btnStyle="secondary"
            btnSize="medium"
            fullWidth
            prependIcon="MdCancel"
            onClick={onCancelClick}
            disableStatus={!media.length}
          /> */}
        </div>
      </div>
    )
  }

  const onMediaRemove = file => {
    const NewMedias = _.union([], _.pull(media, file))

    console.log('PhotoId:', file.id)

    setMedia(NewMedias)

    const updatedObj = {
      media: NewMedias
    }

    setEditStateFn(file, false, true)
  }

  const onMediaEdit = active => {
    setModalEditOpen(true)
    setActiveMedia(active)
  }

  const onMediaAdd = files => {
    var addedMedias = []
    _.map(files, function(file) {
      addedMedias.push(file)
    })

    const NewMedias = _.union(NewMedias, addedMedias)
    setMedia(NewMedias)
  }

  return (
    <Form className={styles.wrapper}>
      <div className="pointer">
        <span style={{ cursor: "pointer"}}>
          <a id="return-to-list" onClick={onBackClick}>
            <MdKeyboardArrowLeft /> Go Back
          </a>
        </span>
      </div>
      <br />
      <h2>Edit Feature Stories</h2>
      <GenericMessage
        message={_message}
        type={_messageType ? _messageType : "success"}
        closeIcon
        setTime
      />
      <div className={styles.container}>
        <div className={styles.detailLeft}>
            {getModalWindow()}
            <FeaturedMediaDisplay
              medias={media}
              mediaType='Photo'
              handleRemoveFn={onMediaRemove}
              handleAddFn={onMediaAdd}
              handleEditFn={onMediaEdit}
            ></FeaturedMediaDisplay>
        </div>
        <div className={styles.detailRight}>
          <div className={styles.actionGroup}>{getActions()}</div>
        </div>
      </div>
    </Form>
  )
}

FeaturedStoriesDetailsEdit.defaultProps = {
  itemTags: [],
}

FeaturedStoriesDetailsEdit.propTypes = {
  item: PropTypes.object.isRequired,
  itemTags: PropTypes.array,
  message: PropTypes.string,
  setEditStateFn: PropTypes.func.isRequired,
}

export default FeaturedStoriesDetailsEdit
